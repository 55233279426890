<template>
  <div>
    <div class="tech-req__title">
      {{ text.menu_gears[language] }}
    </div>
    <div v-if="showEdit" style="margin-bottom: 20px" class="tech-req__card tech-req__card_plus">
      <div v-if="!disableAdd" @click="addGear" style="height: 100%; width: 100%">
        <div class="block block__center">
          <Plus />
        </div>
        <div class="block block__center">
          <div class="tech-req__card_plus_text">
            {{ text.g_surface_add[language] }}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="block block__center block__column block__align-center">
          <div class="tech-req__card_plus_text">
            {{ text.r_surface_select[language] }}
          </div>
        </div>
      </div>
    </div>
    <div v-for="(gear, index) in gears" v-bind:key="`gear_${gear.id}`">
      <div
        :class="`tech-req__block_wrapper ${gear.id === activeId ? 'tech-req__card_active' : ''}`"
      >
        <div class="tech-req__block_container">
          <div class="block block__between block__align-center" style="margin-bottom: 20px">
            <div class="block__flex block__align-bottom">
              <div>{{ text.r_surface_count[language] }}</div>
              <div class="tech-req__card_count">
                {{ gear.surfaces.length }}
              </div>
            </div>
            <div class="block__flex block__align-center">
              <Info />
              <Close v-if="showEdit" @click="deleteGear(index)" class="component__margin-left" />
            </div>
          </div>
          <div style="margin-bottom: 15px">
            <div>
              <Select
                v-if="showEdit && gear.edit"
                show-arrow
                :margin-bottom="0"
                @change="setData($event.value, 'gear_type', index)"
                :dropdown-props="{
                  label: 'label',
                  value: 'value',
                }"
                :label="text.g_gear_type[language]"
                :default-value="
                  gear.data.gear_type ? setGearTypeDefaultValue(gear.data.gear_type, language) : ''
                "
                :values="setGearTypeValues(language)"
              />
              <div v-if="(!showEdit || !gear.edit) && gear.data.gear_type">
                <div class="tech-req__block_subtitle" style="text-transform: unset !important">
                  {{ text.g_gear_type[language] }}
                </div>
                <div>
                  {{
                    gear.data.gear_type
                      ? setGearTypeDefaultValue(gear.data.gear_type, language)
                      : ""
                  }}
                </div>
              </div>
            </div>
            <div v-if="gear.data.gear_type">
              <Select
                v-if="showEdit && gear.edit"
                show-arrow
                :margin-bottom="0"
                @change="setData($event.value, 'tooth_type', index)"
                :dropdown-props="{
                  label: 'label',
                  value: 'value',
                }"
                :label="text.g_tooth_type[language]"
                :default-value="
                  gear.data.tooth_type
                    ? setToothTypeDefaultValue(gear.data.tooth_type, language)
                    : ''
                "
                :values="setToothTypeValues(gear.data.gear_type, language)"
              />
              <div v-if="(!showEdit || !gear.edit) && gear.data.tooth_type">
                <div class="tech-req__block_subtitle" style="text-transform: unset !important">
                  {{ text.g_tooth_type[language] }}
                </div>
                <div>
                  {{
                    gear.data.tooth_type
                      ? setToothTypeDefaultValue(gear.data.tooth_type, language)
                      : ""
                  }}
                </div>
              </div>
            </div>
            <div v-if="gear.data.gear_type && gear.data.tooth_type">
              <Select
                v-if="showEdit && gear.edit"
                show-arrow
                :margin-bottom="0"
                @change="setData($event.value, 'profile_type', index)"
                :dropdown-props="{
                  label: 'label',
                  value: 'value',
                }"
                :label="text.g_tooth_profile[language]"
                :default-value="
                  gear.data.profile_type
                    ? setProfileTypeDefaultValue(gear.data.profile_type, language)
                    : ''
                "
                :values="setProfileTypeValues(gear.data.gear_type, gear.data.tooth_type, language)"
              />
              <div v-if="(!showEdit || !gear.edit) && gear.data.profile_type">
                <div class="tech-req__block_subtitle" style="text-transform: unset !important">
                  {{ text.g_tooth_profile[language] }}
                </div>
                <div>
                  {{
                    gear.data.profile_type
                      ? setProfileTypeDefaultValue(gear.data.profile_type, language)
                      : ""
                  }}
                </div>
              </div>
            </div>
            <div v-if="gear.data.gear_type && gear.data.profile_type">
              <div
                v-for="param in setParams(gear.data.gear_type, gear.data.profile_type)"
                v-bind:key="`gear_param_${param.id}`"
              >
                <div v-if="param.type === 'input'">
                  <div v-if="showEdit && gear.edit">
                    <div class="tech-req__gears_param">
                      {{ param.label[language] }}
                    </div>
                    <div class="block block__align-center">
                      <div
                        class="tech-req__gears_formula"
                        style="white-space: nowrap; min-width: 35px"
                      >
                        <span class="tech-req__gears_letter" style="margin-bottom: 5px">
                          {{ param.title }}
                        </span>
                        <span class="tech-req__gears_index" v-if="param.index">
                          {{ param.index }}
                        </span>
                        <span class="tech-req__gears_letter"> =</span>
                      </div>
                      <Input
                        @change="setData($event, param.name, index)"
                        :default-value="gear.data[param.name] ? gear.data[param.name] : ''"
                        style="width: 100%"
                        :margin-bottom="0"
                        type="number"
                        class="component__margin-left"
                      />
                    </div>
                  </div>
                  <div v-if="(!showEdit || !gear.edit) && gear.data[param.name]">
                    <div class="tech-req__block_subtitle" style="text-transform: unset !important">
                      {{ param.label[language] }}
                    </div>
                    <div class="block__flex block__align-center">
                      <div
                        class="tech-req__gears_formula"
                        style="white-space: nowrap; min-width: 35px"
                      >
                        <span class="tech-req__gears_letter" style="margin-bottom: 5px">
                          {{ param.title }}
                        </span>
                        <span class="tech-req__gears_index" v-if="param.index">
                          {{ param.index }}
                        </span>
                        <span class="tech-req__gears_letter"> =</span>
                      </div>
                      <div class="tech-req__gears_letter">{{ gear.data[param.name] }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block block__right block__align-center" style="margin-top: 20px">
              <Button
                v-if="gear.edit"
                type="primary"
                size="small"
                @click="saveGear(index)"
                :text="text.r_save[language]"
              />
              <Button
                @click="editGear(index)"
                type="primary"
                size="small"
                :text="text.sp_edit[language]"
                v-if="!gear.edit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Plus from "@/assets/images/tech/plus.svg";
import text from "@/assets/text/techV2.json";
import Select from "@/components/select/Select.vue";
import Input from "@/components/input/Input.vue";
import Button from "@/components/button/Button.vue";
import { query } from "@/utilities/axios";

const Info = () => import("@/assets/images/tech/info.svg");
const Close = () => import("@/assets/images/tech/close.svg");

export default {
  name: "Gears",
  data() {
    return {
      text,
      activeClick: false,
      disableAdd: false,
      activeId: null,
      gears: [],
      showEdit: true,
      clickColor: { r: 0.674, g: 0.074, b: 0.145 },
    };
  },
  props: {
    edit: {
      type: Boolean,
      default: true,
    },
    partData: {
      type: Object,
    },
  },
  watch: {
    edit: function (val) {
      this.showEdit = val;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    gearTypes() {
      return this.$store.state.techReq.gearTypes;
    },
    toothTypes() {
      return this.$store.state.techReq.gearToothTypes;
    },
    profileTypes() {
      return this.$store.state.techReq.gearProfileTypes;
    },
    params() {
      return this.$store.state.techReq.gearParams;
    },
  },
  methods: {
    surfaceClicked(name) {
      if (this.activeClick) {
        const result = [];
        if (this.activeId || this.activeId === 0) {
          const index = this.gears.findIndex((el) => (el.id = this.activeId));
          const gears = this.gears.slice();
          const surfaceIndex = gears[index].surfaces.findIndex((el) => el === name);
          if (surfaceIndex < 0) {
            if (gears[index] && gears[index].surfaces) {
              gears[index].surfaces.push(name);
            }
          } else {
            gears[index].surfaces.splice(surfaceIndex, 1);
          }
          result.push({
            surfaces: gears[index].surfaces,
            color: this.clickColor,
          });
          this.gears = gears;
        }
        this.$store.state.modelV2.decorateSurfaces(result);
      }
    },
    addGear() {
      if (!this.disableAdd) {
        this.$store.commit("modelV2/setShowModelFrame", true);
        this.disableAdd = true;
        this.activeClick = true;
        this.activeId = this.gears.length + 1;
        this.gears.unshift({
          id: this.gears.length + 1,
          surfaces: [],
          edit: true,
          data: {
            gear_type: null,
            tooth_type: null,
            profile_type: null,
          },
        });
      }
    },
    deleteGear(index) {
      this.disableAdd = false;
      this.activeClick = false;
      this.$store.commit("modelV2/setShowModelFrame", false);
      this.$store.state.modelV2.clearSurfaces();
      this.gears.splice(index, 1);
    },
    setGearTypeValues(lang) {
      const result = [];
      for (const item of Object.keys(this.gearTypes)) {
        result.push({
          label: this.gearTypes[item].label[lang],
          value: this.gearTypes[item].id,
        });
      }
      return result;
    },
    setGearTypeDefaultValue(id, lang) {
      return this.gearTypes[id].label[lang];
    },
    setToothTypeValues(id, lang) {
      const result = [];
      const types = this.gearTypes[id].tooth_types;
      for (const el of types) {
        result.push({
          label: this.toothTypes[el].label[lang],
          value: this.toothTypes[el].id,
        });
      }
      return result;
    },
    setToothTypeDefaultValue(id, lang) {
      return this.toothTypes[id].label[lang];
    },
    setProfileTypeValues(typeId, toothTypeId, lang) {
      const result = [];
      if (
        this.gearTypes &&
        this.gearTypes[typeId] &&
        this.gearTypes[typeId].profile_types &&
        this.gearTypes[typeId].profile_types[toothTypeId]
      ) {
        const types = this.gearTypes[typeId].profile_types[toothTypeId];
        for (const el of types) {
          result.push({
            label: this.profileTypes[el].label[lang],
            value: this.profileTypes[el].id,
          });
        }
      }
      return result;
    },
    setProfileTypeDefaultValue(id, lang) {
      return this.profileTypes[id].label[lang];
    },
    setData(value, name, index) {
      const gears = this.gears.slice();
      gears[index].data[name] = value;
      this.gears = gears;
    },
    setParams(typeId, profileId) {
      const result = [];
      if (
        this.gearTypes &&
        this.gearTypes[+typeId] &&
        this.gearTypes[+typeId].parameters &&
        this.gearTypes[+typeId].parameters[+profileId]
      ) {
        const types = this.gearTypes[+typeId].parameters[+profileId];
        for (const el of types) {
          result.push(this.params[el]);
        }
      }
      return result;
    },
    // сохраняем элемент
    saveGear(index) {
      // разрешаем добавлять
      this.disableAdd = false;
      // выключаем возможность нажимать
      this.activeClick = false;
      // убираем рамку
      this.$store.commit("modelV2/setShowModelFrame", false);
      // убираем активный id
      this.activeId = null;
      // очищаем раскраску поверхностей
      this.$store.state.modelV2.clearSurfaces();
      // изменияем статус редактирования
      this.setEdit(index);
      this.saveGears();
    },
    editGear(index) {
      // ищем есть ли несохраненные изменения
      // убираем активный id
      this.activeId = index + 1;
      this.activeClick = true;
      this.$store.commit("modelV2/setShowModelFrame", false);
      // изменияем статус редактирования
      this.setEdit(index);
    },
    // меняем статус редактируемого элемента, все устанавливаются в false, а необходимый в обратное
    // значение
    setEdit(index) {
      const gears = this.gears.map((el, i) => {
        const gear = el;
        if (i === index) {
          gear.edit = !gear.edit;
        } else {
          gear.edit = false;
        }
        return gear;
      });
      this.gears = gears;
    },
    getGears() {
      return new Promise((resolve) => {
        query(
          "post",
          "getTechDataByPartOrder",
          {
            gears: true,
            uuid: this.$route.params.uuid,
          },
          {
            token: localStorage.getItem("token"),
          },
        ).then((result) => {
          this.gears = result.gears.gears ? result.gears.gears : [];
          resolve();
        });
      });
    },
    saveGears() {
      query(
        "post",
        "changeTechDataByPartOrder",
        {
          hash: this.partData.hash,
          time_stamp: this.partData.time_stamp,
          file_name: this.partData.file_name,
          uuid: this.$route.params.uuid,
          gears: {
            gears: this.gears,
          },
        },
        {
          token: localStorage.getItem("token"),
        },
      );
    },
  },
  mounted() {
    this.showEdit = this.edit;
    this.getGears();
    this.$store.commit("modelV2/setSurfaceClick", this.surfaceClicked);
  },
  components: {
    Plus,
    Info,
    Close,
    Select,
    Input,
    Button,
  },
};
</script>
